<template>
    <b-card-actions action-collapse title="Admin Form">  
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                      <b-col
          cols="12"
          class="mb-2"
        >
          <div class="border rounded p-2">
            <h4 class="mb-1">
              Avatar
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                 id="profile"
                  ref="refPreviewEl"
                  :src="input.avatar_pathurl"
                  height="110"
                  width="170"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>

                <small class="text-muted">(Recommend Size : 200x200)</small>
                <b-card-text class="my-50">
                  <b-link id="blog-image-text">
                    C:\fakepath\{{ input.userFile ? input.userFile.name : 'banner.jpg' }}
                  </b-link>
                </b-card-text>
                <div class="d-inline-block">
                  <b-form-file
                    ref="refInputEl"
                    v-model="input.userFile"
                    accept=".jpg, .png, .gif"
                    placeholder="Choose file"
                    @input="inputImageRenderer"
                  />
                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label>Email</label>
                             <validation-provider
                                #default="{ errors }"
                                rules="email"
                                name="email"
                                >
                                <b-form-input
                                    v-model="input.email"
                                    placeholder="Email"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        
                        
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label>Phone</label>
                            
                            <validation-provider
                                 #default="{ errors }"
                                rules="min:0"
                                name="phone"
                                >
                                <b-form-input
                                    v-model="input.phone_no"
                                    placeholder="Phone"
                                />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                              
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label>Password</label>
                            <validation-provider
                            #default="{ errors }"
                            name="Password"
                            vid="Password"
                            rules="password"
                            >
                            <b-form-input
                                v-model="input.password"
                                type="password"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Your Password"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label>Confirm Password</label>
                            <validation-provider
                            #default="{ errors }"
                            name="Password Confirm"
                            rules="confirmed:Password"
                            >
                            <b-form-input
                                v-model="passwordCon"
                                :state="errors.length > 0 ? false:null"
                                type="password"
                                placeholder="Confirm Password"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label>First name</label>
                             <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="firstname"
                                >
                                <b-form-input
                                    v-model="input.firstname"
                                    placeholder="First Name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        
                        
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label>Last Name</label>
                            <validation-provider
                                 #default="{ errors }"
                                rules="min:0"
                                name="lastname"
                                >
                               <b-form-input
                                    v-model="input.lastname"
                                    placeholder="Last Name"
                                />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                                
                               
                        </b-form-group>
                    </b-col>
                     <b-col md="12">
                        <b-form-group>
                            <label>Comment</label>
                            <validation-provider
                                 #default="{ errors }"
                                rules="min:0"
                                name="comment"
                                >
                              <b-form-textarea
                                    v-model="input.comment"
                                    placeholder="Comment"
                                />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                                
                             
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group>
                            <label>Address</label>
                                 <validation-provider
                                 #default="{ errors }"
                                rules="min:0"
                                name="address"
                                >
                               <b-form-textarea
                                    v-model="input.address"
                                    placeholder="Address"
                                />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                               
                                
                        </b-form-group>
                    </b-col>
                     <b-col md="3">
                        <b-form-group>
                            <label>Country</label>
                             <validation-provider
                                 #default="{ errors }"
                                rules="required"
                                name="country"
                                >
                              <b-form-input
                                    v-model="input.country"
                                    placeholder="Country"
                                />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                               
                               
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group>
                            <label>Group</label>
                           <validation-provider
                                 #default="{ errors }"
                                rules="required"
                                name="type_id"
                                >
                              <v-select
                                            v-model="input.type_id"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            label="name"
                                            :options="groups"
                                    />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                                
                               
                        </b-form-group>
                    </b-col>
                     <b-col md="3">
                        <b-form-group>
                            <label>Active</label>
                            <validation-provider
                                 #default="{ errors }"
                                rules="required"
                                name="is_active"
                                >
                             <b-form-checkbox
                                checked="true"
                                class="custom-control-primary"
                                v-model="input.is_active"
                                switch
                            />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            
                        </b-form-group>  
                        
                    </b-col>
                  
                    <b-col cols="12">
                        <b-button
                            variant="primary"
                            type="submit"
                            @click.prevent="validationForm"
                        >
                            Submit
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card-actions>
</template>
<script>
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormTextarea,BFormRating,BFormCheckbox,BFormCheckboxGroup
} from 'bootstrap-vue'
import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BImg, BFormFile, BLink
} from 'bootstrap-vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import BCardActions from '../../../../@core/components/b-card-actions/BCardActions.vue'
import axios from '@axios'


//popup
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, onUnmounted } from '@vue/composition-api'

import router from '@/router'
import store from "@/store";
import adminStoreModule from '../adminStoreModule'

export default {
    components: {
        BCardActions,
        VueAutosuggest,
        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormTextarea,
        BFormRating,
        vSelect,
        BFormCheckbox,
        BFormCheckboxGroup,
         BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BImg, BFormFile, BLink
       
  },
  directives: {
    Ripple,
  },
  data(){
    return {
        id:router.currentRoute.params.id,
        phone_no:'',
        email:'',
        input : {
            emails:[],
            phones:[],
            email: '',
            phone_no: '',
            password:null,
            firstname:null,
            lastname:null,
            comment:null,
            address:null,
            country:null,
            is_active:true,
            
            userFile:null,
            type_id:{
                id : 1,
                name : 'Super Admin'
            },
            role:'admin',
            avatar_pathurl:null,
            avatar_url:null
        },
        passwordCon: null,
        userEdit: {},
        groups:[]
    }
  },
  setup() {
    const ADMIN_STORE_MODULE_NAME = "app-admin";
    if (!store.hasModule(ADMIN_STORE_MODULE_NAME)) store.registerModule(ADMIN_STORE_MODULE_NAME, adminStoreModule);
    onUnmounted(() => {
        if (store.hasModule(ADMIN_STORE_MODULE_NAME)) store.unregisterModule(ADMIN_STORE_MODULE_NAME);
    });

    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
  created() {
       this.getGroups();
      if(this.id != undefined){
          setTimeout(() => {
                this.getData();
            }, 500);
       
      }
     
  },
  methods: {
    async getGroups(){
        axios.get('users/v1/groups/admin')
        .then(res => { 
        // console.log('res',res);
            this.groups = res.data.data; 
        })

    },
    async getData(){
        let user = []

        await store
            .dispatch('app-admin/fetchUsers', {
                id: this.id
            })
            .then(response => {
                user = response.data.data[0]
            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching User",
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: `${error?.response?.data?.message??'Something went wrong'}`
                    },
                })
            })

        // var user = await axios.get('users/v1/detail/'+this.id);
        if(!user.id){
            this.$router.push('/admin/lists', () => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `ERror`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: `Data not Found.`,
                },
              })
          });
        }else{
            let usr = user;
            if(usr.type_id){
                var types = this.groups.filter(j => j.id == usr.type_id);
                if(types){
                  this.input.type_id =types[0];
                }
            }
            this.input.emails =  usr.emails
            this.input.phones =  usr.phones;
            this.input.firstname =  usr.firstname;
            this.input.lastname =  usr.lastname;
            this.input.comment =  usr.comment;
            this.input.address =  usr.address;
            this.input.country =  usr.country;
            this.input.is_active =  usr.is_active==1?true:false;
            this.input.password =  null;
            this.input.avatar_pathurl = usr.avatar_pathurl;
            this.input.avatar_url = usr.avatar_url;
            this.input.email =  usr.emails[0].email
            this.input.phone_no =  usr.phones[0].phone_no
            this.email = usr.emails[0].email;
            this.phone = usr.phones[0].phone_no;
        }
    },
    makeToast(variant = null,title,message) {
        this.$bvToast.toast(message, {
            title: title,
            variant,
            solid: true,
        })
    },
      validationForm() {
        const userStorage = JSON.parse(localStorage.getItem('userData'))
          this.$refs.simpleRules.validate().then(success => {

              if (success) {

                  if(this.input.type_id){
                      this.input.type_id = this.input.type_id.id

                  }
                   this.input.base64 = '';
                  if(this.input.userFile){
                       this.input.base64 = document.getElementById('profile').src;
                  }

                  var data = JSON.stringify(this.input);

                if(this.id != undefined){
                    this.input.updated_by = userStorage.id

                    const updatedEmail = []
                    const updatedPhoneNum = []

                    updatedEmail.push(this.input.email)
                    updatedPhoneNum.push({ phone_no: this.input.phone_no })
                    this.input.emails = updatedEmail
                    this.input.phones = updatedPhoneNum

                    const formData = new FormData();
                    data = this.input
                    formData.append('imageFile', this.refInputEl.files[0])
                    formData.append('data', JSON.stringify(data))
                    
                    store.dispatch("app-admin/updateUser", {
                        id: this.id,
                        data: formData
                    }).then((res) => {
                        this.$router.push('/admin/list', () => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                title: `Done`,
                                icon: 'CoffeeIcon',
                                variant: 'success',
                                text: `You have successfully updated.`,
                                },
                            })
                        });
                    }).catch((error) => {
                        this.makeToast('danger', 'Error 403', `${error?.response?.data?.message??'Something went wrong'}`)
                    })

                    // axios.put('users/v1/update/'+this.id,data)
                    // .then(res => { 
                      
                    //     if(!res.data.success){
                    //     this.makeToast('danger','Error 403',res.data.message);
                        
                        
                    //     }else{
                    //     this.$router.push('/admin/list', () => {
                    //         this.$toast({
                    //             component: ToastificationContent,
                    //             position: 'top-right',
                    //             props: {
                    //             title: `Done`,
                    //             icon: 'CoffeeIcon',
                    //             variant: 'success',
                    //             text: `You have successfully created.`,
                    //             },
                    //         })
                    //     });
                        

                    //     }
                    // // this.actions = res.data.data; 
                    // }).catch(error => {
                    //     this.makeToast('danger','Error 403',error);
                    //     console.log('error',error);
                    // })
                }else{
                    this.input.created_by = userStorage.id
                    this.input.emails.push(this.input.email)
                    this.input.phones.push({phone_no: this.input.phone_no})

                    const formData = new FormData();
                    data = this.input
                    formData.append('imageFile', this.refInputEl.files[0])
                    formData.append('data', JSON.stringify(data))

                    store.dispatch("app-admin/addUser", {
                        data: formData
                    }).then((res) => {
                        this.$router.push('/admin/list', () => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                title: `Done`,
                                icon: 'CoffeeIcon',
                                variant: 'success',
                                text: `You have successfully created.`,
                                },
                            })
                        });
                    }).catch((error) => {
                        this.makeToast('danger', 'Error 403', `${error?.response?.data?.message??'Something went wrong'}`)
                    })

                    // axios.post('users/v1/add',data)
                    // .then(res => { 
                      
                    //     if(!res.data.success){
                    //     this.makeToast('danger','Error 403',res.data.message);
                        
                        
                    //     }else{
                    //     this.$router.push('/admin/list', () => {
                    //         this.$toast({
                    //             component: ToastificationContent,
                    //             position: 'top-right',
                    //             props: {
                    //             title: `Done`,
                    //             icon: 'CoffeeIcon',
                    //             variant: 'success',
                    //             text: `You have successfully created.`,
                    //             },
                    //         })
                    //     });
                        

                    //     }
                    // // this.actions = res.data.data; 
                    // }).catch(error => {
                    //     this.makeToast('danger','Error 403',error);
                    //     console.log('error',error);
                    // })

                }
                   //this.input.password = 'zero2n!*2520';
                  
                  //users/v1/add
                 
                 
                   
              }
          });
      }

  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>